import { PLAYLIST_ITEM_REQUEST } from '../actions/playlist';
import { apiGetPlaylistItem, apiGetScreenPlaylistItem } from '../../api/playlist';

const state = {};

const getters = {};

const actions = {
  [PLAYLIST_ITEM_REQUEST]: async ({ rootState }, { playlist_id, params }) => {
    const screenId = rootState.player.screenId;

    try {
      const response = !!screenId
        ? await apiGetScreenPlaylistItem(playlist_id, screenId)
        : await apiGetPlaylistItem(playlist_id, params);

      const data = response.data;

      if (!data.items) throw new Error(data);

      let { items } = data;

      if (data.overridden_items) {
        items = data.overridden_items.map((overridden_item, index) => ({
          ...overridden_item,
          ...(overridden_item.override ? overridden_item.override.config : {}),
          item_priority:
            overridden_item.override?.config.item_priority ||
            overridden_item.item_priority ||
            index,
        }));
      }

      items = items.sort((item1, item2) => item1.item_priority - item2.item_priority);

      return { ...response, data: { ...data, overridden_items: items, items } };
    } catch (error) {
      console.log('error: ', error);
    }
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
