import config from '../config';
import http from '@/utils/http';

// ---- TILE CHILDREN ---- //

export function apiGetTileChildren(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;

  return http.get(endpoint);
}

// ---- Tile OVERRIDES ---- //

export const apiGetScreenTileChildren = async (tileId, screenId, playlistId) => {
  const url = `${config.apiUrl}userapi/screens/${screenId}/local-template/${tileId}/children?playlist_id=${playlistId}`;

  return http.get(url);
};
