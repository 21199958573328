import config from '../config';
import http from '@/utils/http';
import { Departure } from '@/types/api/transport';

export async function getDepartures(
  stopId: unknown,
  transportTypes: unknown,
  lang: unknown,
  departuresAfter: unknown,
  duration: number = 60,
): Promise<Departure[]> {
  const apiUrl = `${config.transportApiUrl}/timetables/departures`;
  const response = await http.get(apiUrl, {
    params: {
      stopId: stopId,
      transportTypes: transportTypes,
      lang: lang,
      departuresAfter: departuresAfter,
      duration: duration,
    },
  });

  return response.data;
}
