<template>
  <div :style="style">
    <audio
      :ref="`widget_audio_${widget.position.data.wid}`"
      class="widget-audio"
      :style="audioStyle"
      controls
      loop
      texture
    >
      <source :src="audioUrl" :type="widget.object.item_type" @error="handleAudioError" />
      <source
        v-if="!isWeb"
        :src="defaultAudioUrl"
        :type="widget.object.item_type"
        @error="handleDefaultAudioError"
      />
    </audio>
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';

  export default {
    name: 'AudioWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    mounted() {
      this.playAudio();
    },

    computed: {
      style() {
        return Object.assign({}, this.defaultStyling(this.widget), {
          position: 'absolute',
          overflow: 'hidden',
        });
      },

      isWeb() {
        return window.isWeb;
      },

      audioUrl() {
        return getItemLocalUrl(this.widget.object.item_url, this.widget.object.item_id);
      },

      defaultAudioUrl() {
        return getItemUrl(this.widget.object.item_url);
      },

      audioStyle() {
        return {
          objectFit: this.widget.position.data.imageMode || 'cover',
        };
      },
    },

    methods: {
      playAudio() {
        const audioRef = this.$refs[`widget_audio_${this.widget.position.data.wid}`];
        if (audioRef === null) return;

        audioRef.volume = this.widget.object.volume;
        audioRef.muted = this.widget.object.mute;
        audioRef.currentTime = 0;

        const playPromise = audioRef.play();

        // browsers released before 2019 may not return a value
        if (playPromise !== undefined) {
          playPromise.then((_) => {}).catch((_error) => {});
        }
      },

      handleAudioError() {
        console.warn(`Failed to load: ${this.audioUrl}`);
      },

      handleDefaultAudioError() {
        console.warn(`Failed to load default: ${this.defaultAudioUrl}`);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-audio {
    width: 100%;
    height: 100%;
  }
</style>
