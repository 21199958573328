import config from '../config';
import http from '@/utils/http';

export const apiWeatherCurrentConditions = async (location, language = 'en', type) => {
  const url = new URL('userapi/app/weather/get-weather', config.apiUrl);
  const response = await http.post(url.toString(), {
    location,
    language,
    type,
  });

  return response.data;
};
