<template>
  <div
    class="widget-item button-widget"
    :id="widget.object.wid"
    :title="widget.object.captionText"
    :style="mainStyle"
    @click="openButtonModal"
  >
    <img
      v-if="widget.object.imageAsButton && widget.object.imageUrl"
      :style="imageStyle"
      :src="imageUrl"
      @error="hadleMediaError"
    />
    <span v-else-if="!widget.object.imageAsButton" class="button-text">
      {{ widget.object.text }}
    </span>
  </div>
</template>

<script>
  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import { getLocalUrlFromFullUrl } from '@/utils/mediaAsset';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  import { OPEN_PLAYER_MODAL } from '@/store/actions/player';

  export default {
    name: 'ButtonWidget',

    mixins: [templateWidget, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        style: {
          position: 'absolute',
        },
        mediaErrorHandled: false,
      };
    },

    mounted() {
      this.renderWidget();
    },

    computed: {
      buttonStyle() {
        const button = this.widget.object;

        const embosseStyle = 'linear-gradient(#fff6 5%, #0003 100%)';
        const embosseTextStyle = '1px 1px 3px #666666';
        const borderStyle = `${button.borderWidth}px solid ${getRBGAColor(button.borderColor)}`;
        const shadowStyle = `${button.shadowBlur / 2}px ${button.shadowBlur / 2}px ${
          button.shadowBlur
        }px 0 ${getRBGAColor(button.shadowColor, button.shadowOpacity / 100)}`;

        return {
          backgroundColor: getRBGAColor(button.backgroundColor),
          border: button.displayBorder ? borderStyle : 'none',
          borderRadius: `${button.borderRadius}px`,
          boxShadow: button.displayShadow ? shadowStyle : 'none',
          color: getRBGAColor(button.textColor),
          fontFamily: button.textFont,
          fontWeight: button.fontWeight,
          fontSize: `${button.textSize}px`,
          overflow: 'hidden',
          textShadow: button.embossed ? embosseTextStyle : 'none',
          backgroundImage: button.embossed ? embosseStyle : 'none',
          zIndex: 100 + this.widget.position.zIndex,
        };
      },

      mainStyle() {
        return Object.assign(
          this.style,
          this.widget.object.imageAsButton ? this.style : this.buttonStyle,
        );
      },

      imageStyle() {
        return {
          objectFit: this.widget.object.imageMode,
          height: '100%',
          width: '100%',
        };
      },

      imageUrl() {
        if (this.widget.object.background_item_id) {
          return getLocalUrlFromFullUrl(
            this.widget.object.imageUrl,
            this.widget.object.background_item_id,
          );
        }
        return this.widget.object.imageUrl;
      },
    },

    methods: {
      renderWidget() {
        this.style = this.defaultStyling(this.widget);
      },

      openButtonModal() {
        if (this.widget.object.contentLink) {
          this.$store.commit(OPEN_PLAYER_MODAL, this.widget.object);
        }
      },

      hadleMediaError($event) {
        if (!this.mediaErrorHandled) {
          this.mediaErrorHandled = true;
          $event.target.src = this.widget.object.imageUrl;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-text {
    white-space: -moz-nowrap; /* Firefox */
    white-space: -o-nowrap; /* Opera */
    white-space: nowrap; /* Chrome */
    word-wrap: break-word; /* IE */

    font-weight: inherit;
    font-family: inherit;
  }

  .button-widget {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
