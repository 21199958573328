export const WIDGET_TYPES = {
  LAYER: 'layer',
  PLAYLIST: 'playlist',
  BUTTON: 'button',
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  TEXT: 'text',
  RICH_TEXT: 'richtext',
  TENANTS: 'tenant',
  PDF: 'pdf',
  APP: 'app',
  CLOCK: 'clock',
  LAYOUT_ITEM: 'playlistitem',
  TEMPLATE: 'template',
};

export const APP_TYPES = {
  WEATHER: 'weather',
  TRAFIKLAB: 'trafiklab',
  RSS: 'rss',
  TABLE: 'table',
};

export const BUTTON_CONTENT_TYPES = {
  IMAGE: 'image',
  URL: 'url',
  VIDEO: 'video',
};

export const LAYOUT_ASPECT_RATIOS = {
  SIXTEEN_TEN: '16:10',
  SIXTEEN_NINE: '16:9',
  FIVE_FOUR: '5:4',
  FOUR_THREE: '4:3',
  TWENTY_ONE: '21:9',
};

export const LAYOUT_RESOLUTIONS = {
  defaultValues: {
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_TEN]: 'WUXGA',
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE]: 'FHD',
    [LAYOUT_ASPECT_RATIOS.FIVE_FOUR]: 'SXGA',
    [LAYOUT_ASPECT_RATIOS.FOUR_THREE]: 'XGA',
    [LAYOUT_ASPECT_RATIOS.TWENTY_ONE]: 'UWFHD',
  },
  resolutions: {
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_TEN]: {
      'WXGA-16': { name: 'WXGA-16', ratio: '16:10', width: 1280, height: 800 },
      'WXGA+': { name: 'WXGA+', ratio: '16:10', width: 1440, height: 900 },
      'WSXGA+': { name: 'WSXGA+', ratio: '16:10', width: 1680, height: 1050 },
      WUXGA: { name: 'WUXGA', ratio: '16:10', width: 1920, height: 1200 },
      WQXGA: { name: 'WQXGA', ratio: '16:10', width: 2560, height: 1600 },
    },
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE]: {
      WXGA: { name: 'WXGA', ratio: '16:9', width: 1280, height: 720 },
      HD: { name: 'HD', ratio: '16:9', width: 1360, height: 768 },
      'HD+': { name: 'HD+', ratio: '16:9', width: 1600, height: 900 },
      '4K UHD': { name: '4K UHD', ratio: '16:9', width: 3840, height: 2160 },
      FHD: { name: 'FHD', ratio: '16:9', width: 1920, height: 1080 },
      QWXGA: { name: 'QWXGA', ratio: '16:9', width: 2048, height: 1152 },
      QHD: { name: 'QHD', ratio: '16:9', width: 2560, height: 1440 },
    },
    [LAYOUT_ASPECT_RATIOS.FIVE_FOUR]: {
      SXGA: { name: 'SXGA', ratio: '5:4', width: 1280, height: 1024 },
    },
    [LAYOUT_ASPECT_RATIOS.FOUR_THREE]: {
      SVGA: { name: 'SVGA', ratio: '4:3', width: 800, height: 600 },
      XGA: { name: 'XGA', ratio: '4:3', width: 1024, height: 768 },
      QXGA: { name: 'QXGA', ratio: '4:3', width: 2048, height: 1536 },
    },
    [LAYOUT_ASPECT_RATIOS.TWENTY_ONE]: {
      UWFHD: { name: 'UWFHD', ratio: '21:9', width: 2560, height: 1080 },
      UWQHD: { name: 'UWQHD', ratio: '21:9', width: 3440, height: 1440 },
    },
  },
};

export const IMAGE_MODE = {
  ORIGIN: 'none',
  CONTAIN: 'contain',
  STRETCH: 'fill',
  CROP: 'cover',
};

export const WIDGET_CONTENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  NONE: 'none',
  APP: 'app',
  URL: 'url',
  PDF: 'pdf',
};

export const ALIGN_MODES = {
  TEXT_MODE: 'text',
  RSS_MODE: 'rss',

  LEFT_ALIGN: 'left',
  CENTER_ALIGN: 'center',
  RIGHT_ALIGN: 'right',
};
