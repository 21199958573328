<template>
  <iframe
    class="embed-responsive-item"
    allow="accelerometer"
    :src="getYoutubeUrl(slide)"
    allowfullscreen
    scrolling="no"
    title="youtube video"
  ></iframe>
</template>

<script>
  export default {
    props: ['slide', 'isMuted'],
    methods: {
      getYoutubeUrl(itemData) {
        let url = itemData.item_url;
        if (this.isMuted(itemData)) {
          const urlParts = url.split('#');
          if (urlParts.length) {
            const urlQueryStrings = urlParts[0].split('?');
            if (urlQueryStrings.length > 1) {
              urlQueryStrings[1] += '&mute=1';
            } else {
              urlQueryStrings[0] += '?mute=1';
            }
            urlParts[0] = urlQueryStrings.join('?');

            url = urlParts.join('#');
          } else {
            url += '&mute=1';
          }
        }
        return url;
      },
    },
  };
</script>
