<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Button',
  });
</script>

<script setup lang="ts">
  import { toRefs, defineEmits } from 'vue';

  const props = withDefaults(
    defineProps<{
      focus?: boolean;
    }>(),
    {
      focus: false,
    },
  );
  const { focus } = toRefs(props);

  const emit = defineEmits<{ (event: 'click'): void }>();
</script>

<template>
  <button
    class="ui-button ui-button-primary"
    :class="{ 'ui-button-primary-focus': focus }"
    @click="() => emit('click')"
  >
    <span class="text"><slot></slot></span>
  </button>
</template>

<style scoped lang="scss">
  @import '@/sass/variables';

  .ui-button {
    padding: 6px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;

    .text {
      font-family: 'Poppins', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: inherit;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    // Primary
    &.ui-button-primary {
      color: white;
      background: $primaryRed;

      &:not(:disabled):hover {
        border-color: #ae002b;
      }

      &.ui-button-primary-focus {
        color: #ffffff;
        background-color: $primaryRed;
        border-color: $primaryRed;
        box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5);
      }

      &.ui-button-outline {
        background: transparent;
        color: $primaryRed;
        border-color: $primaryRed;
        &:not(:disabled):hover {
          background: $primaryRed;
          color: white;
        }
      }
    }
  }
</style>
