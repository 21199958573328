import config from '../config';
import http from '@/utils/http';

export const apiGetScreenTemplateChildren = async (templateId, screenId, playlistId) => {
  return http.get(
    `${config.apiUrl}userapi/screens/${screenId}/template/${templateId}/children${
      playlistId ? `?playlist_id=${playlistId}` : ''
    }`,
  );
};
