// match the thumbnail size in other page, e.g. rss apps page
// for consistent thumbnail size across all card grid / list
export const THUMBNAIL_WIDTH = 250;
export const THUMBNAIL_HEIGHT = 182;

export const MATCHI_AVAILABLE_SPORTS = [
  { name: 'Badminton', tag: 'badminton', icon: 'BadmintonIcon.png' },
  { name: 'Padel', tag: 'padel', icon: 'PadelIcon.png' },
  { name: 'Pickle Ball', tag: 'pickleball', icon: 'PickleballIcon.png' },
  { name: 'Squash', tag: 'squash', icon: 'SquashIcon.png' },
  { name: 'Tennis', tag: 'tennis', icon: 'TennisIcon.png' },
  { name: 'Table Tennis', tag: 'tabletennis', icon: 'TableTennisIcon.png' },
  { name: 'Golf', tag: 'golf', icon: 'GolfIcon.png' },
  { name: 'Floorball', tag: 'floorball', icon: 'FloorballIcon.png' },
  { name: 'Trampoline', tag: 'trampoline', icon: 'TrampolineIcon.png' },
  { name: 'Yoga', tag: 'yoga', icon: 'YogaIcon.png' },
  { name: 'Cageball', tag: 'cageball', icon: 'TennisIcon.png' },
  { name: 'Other', tag: 'other', icon: 'OtherIcon.svg' },
];

export const TL_LANGUAGES = {
  English: 'en',
  Swedish: 'sv',
};

export const TL_TRAFFIC_TYPES = ['BUS', 'METRO', 'TRAIN', 'TRAM', 'SHIP'];
export const REGISTERED_APPS = ['app/rss', 'app/table'];

export const LICENSE_STATUSES = {
  active: 'Active',
  expired: 'Expired',
  unused: 'Not Used',
  removed: 'Removed',
};

export const REFRESH_LAYOUT_STATUS = {
  SELECTING: 'SELECTING',
  RESIZING: 'RESIZING',
  MOVING: 'MOVING',
  STAND_BY: 'STAND_BY',
  UPDATING: 'UPDATING',
};

export const NUMBER_OF_RETRIES = 5;
