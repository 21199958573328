import config from '@/config';
import { DetailedAddress } from '@/types/api/tenants';
import { stringify } from 'qs';
import http from '@/utils/http';

export async function apiGetDetailedAddress(
  externalId: string,
  floors?: number[],
  sortByFloorOrder: boolean = true,
): Promise<DetailedAddress> {
  const response = await http.get<DetailedAddress>(
    `${config.apiUrl}userapi/integrations/tenant-integration/detailed-address`,
    {
      params: {
        externalId: externalId,
        floors: floors,
        sortByFloorOrder: sortByFloorOrder,
      },
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );

  return response.data;
}
