<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PageNotFound',
  });
</script>

<script setup lang="ts">
  import { onMounted, onBeforeUnmount } from 'vue';
  import Button from '@/components/common/Button.vue';
  import { useRouter } from '@/router';

  const router = useRouter();

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  function handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();

    if (event.code === 'Enter') {
      navigateToHome();
    }
  }

  function navigateToHome() {
    router.push('/');
  }
</script>

<template>
  <div class="page-not-found-container">
    <div class="content">
      <h1 class="title-text">404</h1>
      <h2>Page not found</h2>
      <h6>Oops! The page you are looking for does not exist.</h6>
      <Button class="styled-button" :focus="true" @click="navigateToHome">Go back to home</Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '@/sass/variables.scss';

  .page-not-found-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: $primaryYellow;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    min-width: 400px;
    text-align: center;
    align-content: center;
    align-items: center;
  }

  .title-text {
    font-size: 64px;
  }

  .styled-button {
    margin-top: 24px;
    width: 264px;
  }
</style>
