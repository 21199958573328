<template>
  <div id="app">
    <RefreshTokenText v-if="initialRefreshTokenLoading" />
    <DownloadingMediaAssetLoader v-else-if="isFontsLoading || isSynchronizingMediaAssets" />
    <router-view v-else />

    <PlayerModal v-if="showPlayerModal" />
  </div>
</template>

<script>
  import RefreshTokenText from '@/components/RefreshTokenText.vue';
  import PlayerModal from '@/components/player/PlayerModal.vue';

  import DownloadingMediaAssetLoader from '@/components/common/ui/dowloadingMediaAssetLoader/DownloadingMediaAssetLoader.vue';
  import { usePicflowFonts } from '@/composables/usePicflowFonts/usePicflowFonts';

  import socketMixin from '@/mixins/socket';
  import PicflowSentryPlugin from '@/plugins/picflowSentryPlugin';

  import { getScreenInformation } from '@/api/screenInformation';
  import { isLoggedIn } from '@/services/auth';
  import { handlePublish } from './services/screenInformation';

  import config from '@/config';
  import { SESSION_TOKEN_ENUM, PLAYER_ORIENTATION } from './constants';
  import { getDeviceSpecificParam } from '@/helpers/utils';
  import { FileSynchronizationManager } from '@/utils/internalFileStorage/fileSynchronizationManager';
  import { getWebOsDeviceInfo, webOSAppInitialization } from '@/utils/webOS';

  window.jQuery = require('jquery');
  require('./plugins/jquery.textfill.min');

  const default_layout = 'dashboard';

  export default {
    name: 'app',
    components: { DownloadingMediaAssetLoader, RefreshTokenText, PlayerModal },
    mixins: [socketMixin],

    data() {
      return {
        initialRefreshTokenLoading: true,
        isSynchronizingMediaAssets: false,
      };
    },

    setup() {
      const { isLoading: isFontsLoading, loadFonts } = usePicflowFonts();

      return { isFontsLoading, loadFonts };
    },

    methods: {
      emitDeviceInfo(deviceInfo) {
        this.$socket.emit('device_info', {
          type: 'device_info',
          auth: localStorage.getItem('sessionToken'),
          data: {
            ...deviceInfo,
          },
        });
      },

      async checkPublishStatus() {
        try {
          const screenInfo = await getScreenInformation();
          if (screenInfo.data.publish_id_player !== screenInfo.data.publish_id_actual) {
            await handlePublish(screenInfo.data.publish_id_actual);
            window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
          }
        } catch (error) {
          console.log('Error in publish status interval function', error);
        }
      },

      async startSynchronizingMediaAssets() {
        if (window.isWebOS && this.isSynchronizingMediaAssets === false) {
          this.isSynchronizingMediaAssets = true;

          const maxRetries = 3;
          var retryCount = 0;
          var allFileSynced = false;

          while (!allFileSynced && retryCount < maxRetries) {
            allFileSynced = await FileSynchronizationManager.runFileSynchronizationOnce();

            if (!allFileSynced) {
              const exponentialBackoffDelay = Math.pow(retryCount, 2) * 1000;
              await new Promise((resolve) => {
                setTimeout(resolve, exponentialBackoffDelay);
              });

              ++retryCount;
              console.log(`Sync retry count ${retryCount}`);
            }
          }

          this.isSynchronizingMediaAssets = false;
        }
      },
    },

    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      },

      showPlayerModal() {
        return this.$store.state.player.showPlayerModal;
      },
    },

    async created() {
      window.ws = this.$socket;
      if (isLoggedIn()) {
        this.$socket.emit('join', {
          auth: localStorage.getItem(SESSION_TOKEN_ENUM),
        });
        this.refreshToken();
      } else {
        this.initialRefreshTokenLoading = false;
      }

      window.refreshTokenTimer = setInterval(() => {
        if (isLoggedIn()) this.refreshToken();
      }, 1000 * 60 * 10);

      if (process.env.VUE_APP_AVOID_SCREEN_INFO !== 'true') {
        // Made it to 61 seconds so that this runs after device info to avoid possible screen info updates from 2 places at once
        window.publishStatusTimer = setInterval(() => {
          if (isLoggedIn()) this.checkPublishStatus();
        }, 1000 * 61);
      }
    },

    async mounted() {
      // should only run in production
      if (
        config.sentry.startSessionReplayOnMounted &&
        (window.isWebOS === true || window.isAndroid === true)
      ) {
        PicflowSentryPlugin.dispatchStartSessionReplayEvent();
      }

      if (isLoggedIn()) {
        await this.loadFonts();
      }

      if (window.isWebOS === true) {
        // we need to wait for all external scripts before we run webOS native code
        await webOSAppInitialization();
      }

      if (window.isAndroid) {
        // Force android to change orientation based on value saved in localstorage.
        // Otherwise the app always starts in landscape mode after app restart.
        const orientation = localStorage.getItem(PLAYER_ORIENTATION);
        if (window.changeOrientation && orientation) {
          window.changeOrientation(orientation);
        }
      }

      window.deviceInfoTimer = setInterval(async () => {
        if (isLoggedIn()) {
          if (window.isWebOS) {
            const deviceInfo = await getWebOsDeviceInfo();
            this.emitDeviceInfo(deviceInfo);
          } else if (window.getDeviceInfo) {
            // android and web
            window.getDeviceInfo();
          }
        }
      }, 1000 * 60);

      if (isLoggedIn()) {
        await this.startSynchronizingMediaAssets();
      }
    },

    beforeDestroy() {
      if (window.deviceInfoTimer) clearInterval(window.deviceInfoTimer);
      if (window.refreshTokenTimer) clearInterval(window.refreshTokenTimer);
      if (window.publishStatusTimer) clearInterval(window.publishStatusTimer);
    },
  };
</script>
