import { screenLayoutLink } from '@/constants/routes';
import http from '@/utils/http';

export const fetchScreenLayout = async (screenId) => {
  try {
    let result = await http.get(screenLayoutLink(screenId));
    return {
      success: true,
      data: result,
    };
  } catch (e) {
    return {
      success: false,
      message:
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : e.message,
    };
  }
};
