import { WEBOS_RELATIVE_PATH } from '@/constants/webOS';
import config from '@/config';

function replaceItemUrlWithFileName(path, newFileName) {
  const parts = path.split('/');
  const directory = parts[0];
  const fileName = parts[parts.length - 1];

  const extension = fileName.split('.').pop();

  return `${directory}/${newFileName}.${extension}`;
}

export function getItemLocalUrl(itemUrl, itemId) {
  if (window.isWebOS) {
    try {
      return `${WEBOS_RELATIVE_PATH}/${replaceItemUrlWithFileName(itemUrl, itemId)}`;
    } catch (error) {
      console.error('Failed to get internal files');
    }
  }

  return `${config.baseUrl}/${itemUrl}`;
}

// Button images are full URL.
// Can't change that as it will affect backward compatibility therefire buttons need to call this method for local URL.
export function getLocalUrlFromFullUrl(fullUrl, itemId) {
  const regex = new RegExp(`${config.baseUrl}/`, 'i');
  const itemUrl = fullUrl.replace(regex, '');

  return getItemLocalUrl(itemUrl, itemId);
}

export function getItemUrl(itemUrl) {
  return `${config.baseUrl}/${itemUrl}`;
}
