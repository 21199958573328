import config from '../config';
import http from '@/utils/http';

export async function apiGetScreenInternalStorage(screenId) {
  const response = await http.get(`${config.apiUrl}userapi/screens/${screenId}/internal-storage`);

  return response.data;
}

export async function apiCreateScreenInternalStorage(screenId, data) {
  const response = await http.post(`${config.apiUrl}userapi/screens/${screenId}/internal-storage`, {
    screenId: screenId,
    fileDownloadHistory: data.fileDownloadHistory,
    internalFiles: data.internalFiles,
  });
  return response.data;
}

export async function apiUpdateScreenInternalStorage(screenId, data) {
  const response = await http.put(`${config.apiUrl}userapi/screens/${screenId}/internal-storage`, {
    id: data.id,
    screenId: screenId,
    fileDownloadHistory: data.fileDownloadHistory,
    internalFiles: data.internalFiles,
  });

  return response.data;
}
