import config from '../config';
import http from '@/utils/http';

// PlaylistItem
export const apiGetPlaylistItem = (playlist_id, params = {}) => {
  return http.get(`${config.apiUrl}userapi/playlist/${playlist_id}/item`);
};

export const apiGetScreenPlaylistItem = (playlistId, screenId) => {
  return http.get(`${config.apiUrl}userapi/screens/${screenId}/playlist/${playlistId}/children`);
};
