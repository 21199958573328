<template>
  <Loader v-if="isLoading" />
  <div v-else-if="currentSlide && filteredSlides.length > 0" class="playlist-player">
    <div
      v-for="(slide, index) in filteredSlides"
      class="playlist-slide"
      :class="{
        [slideClassIn]: displaySlide(slide),
        [slideClassOut]: !displaySlide(slide),
      }"
      :key="index"
    >
      <PlaylistSlide
        v-if="
          (!(currentSlideItemType === 'video' || currentSlideItemType === 'audio') &&
            index === currentSlideIndex) ||
          (!(nextSlideItemType === 'video' || nextSlideItemType === 'audio') &&
            index === nextSlideIndex)
        "
        :key="`${slide.item_id}-${slide.assoc_id}-${index}`"
        :show="slide.item_id === currentSlide.item_id"
        :slide="{ ...slide, imageMode: playlist.imageMode || 'cover' }"
        :trafiklabZoom="trafiklabZoom"
        :playlist="playlist"
      />
      <!-- Preloading videos in the background to enable seamless playback across multiple videos -->
      <VideoSlide
        v-if="
          (currentSlideItemType === 'video' && index === currentSlideIndex) ||
          (nextSlideItemType === 'video' && index === nextSlideIndex)
        "
        :key="`${slide.item_id}-${slide.assoc_id}-${index}`"
        :show="slide.item_id === currentSlide.item_id"
        :restartSignal="restartSignal"
        :itemId="slide.item_id"
        :itemType="slide?.item_type"
        :itemUrl="slide.item_url"
        :imageMode="slide.imageMode ?? 'cover'"
      />
      <!-- WebOS screens can only play sound from one audio tag at a time so we dont preload audio  -->
      <AudioSlide
        v-if="currentSlideItemType === 'audio' && index === currentSlideIndex"
        :key="`${slide.item_id}-${slide.assoc_id}-${index}`"
        :show="slide.item_id === currentSlide.item_id"
        :restartSignal="restartSignal"
        :itemId="slide.item_id"
        :itemType="slide?.item_type"
        :itemUrl="slide.item_url"
        :imageMode="slide.imageMode ?? 'cover'"
        :mute="slide?.mute ?? false"
        :volume="slide?.volume ?? 1"
      />
    </div>
  </div>
  <div v-else class="playlist-background"></div>
</template>

<script>
  import moment from 'moment';
  import PlaylistSlide from '@/components/player/PlaylistSlide.vue';
  import Loader from '@/components/common/Loader2.vue';
  import { DAYS_OF_THE_WEEK, DISPLAY_PRIORITY } from '@/constants';
  import { PLAYLIST_ITEM_REQUEST } from '@/store/actions/playlist';
  import { simpleTypeMixin } from '@/helpers';
  import VideoSlide from '@/components/player/layerContent/VideoSlide.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import AudioSlide from '@/components/player/layerContent/AudioSlide.vue';

  export default {
    name: 'PlaylistPlayer',

    mixins: [simpleTypeMixin],

    props: {
      playSlider: {
        type: Boolean,
        default: true,
      },
      playlist: {
        type: Object,
        default: () => null,
      },
      trafiklabZoom: {
        type: Number,
        default: 1,
      },
    },

    components: {
      AudioSlide,
      VideoSlide,
      PlaylistSlide,
      Loader,
    },

    data() {
      return {
        isLoading: false,
        currentSlideIndex: 0,
        nextSlideIndex: 0,
        playlistItems: [],
        time: null,
        schedulerTime: null,
        filteredSlides: [],
        videos: null,
        restartSignal: null,
      };
    },

    watch: {
      playlistId() {
        this.getPlaylistData();
      },
    },

    async mounted() {
      if (!this.playlist || !this.playlist.playlist_id) return;

      this.setPlaylist();

      this.schedulerTime = setInterval(this.filterPlaylistsBySchedulerAndPriority, 10 * 1000);
    },

    beforeDestroy() {
      clearInterval(this.time);
      clearInterval(this.schedulerTime);
    },

    computed: {
      playlistId() {
        return this.playlist ? this.playlist.playlist_id : null;
      },

      currentSlide() {
        return this.filteredSlides[this.currentSlideIndex] || this.filteredSlides[0];
      },

      currentSlideItemType() {
        if (this.filteredSlides[this.currentSlideIndex]?.item_type.includes(WIDGET_TYPES.VIDEO)) {
          return WIDGET_TYPES.VIDEO;
        } else if (
          this.filteredSlides[this.currentSlideIndex]?.item_type.includes(WIDGET_TYPES.AUDIO)
        ) {
          return WIDGET_TYPES.AUDIO;
        } else {
          return this.filteredSlides[this.currentSlideIndex]?.item_type;
        }
      },

      nextSlideItemType() {
        if (this.filteredSlides[this.nextSlideIndex]?.item_type.includes(WIDGET_TYPES.VIDEO)) {
          return WIDGET_TYPES.VIDEO;
        } else if (
          this.filteredSlides[this.nextSlideIndex]?.item_type.includes(WIDGET_TYPES.AUDIO)
        ) {
          return WIDGET_TYPES.AUDIO;
        } else {
          return this.filteredSlides[this.nextSlideIndex]?.item_type;
        }
      },

      slides() {
        return this.playlistItems || this.$store.state.playlist.playlistItems;
      },

      videoSlides() {
        return this.filteredSlides.filter(
          (item) => item.item_type && item.item_type.includes('video'),
        );
      },

      isAndroid() {
        return !!window.isAndroid;
      },

      slideClassIn() {
        // Note: template literals are not guarantied to work on older browsers such as webOS 4
        return this.playlist.transition_mode + 'In';
      },

      slideClassOut() {
        // Note: template literals are not guarantied to work on older browsers such as webOS 4
        return this.playlist.transition_mode + 'Out';
      },
    },

    methods: {
      async getPlaylistData() {
        try {
          this.isLoading = true;

          const response = await this.$store.dispatch(PLAYLIST_ITEM_REQUEST, {
            playlist_id: this.playlistId,
          });

          this.playlistItems = response.data.items;

          if (!!this.isAndroid) {
            this.getVideos();
          }
        } catch (error) {
          console.error('error: ', error);
        }

        this.isLoading = false;
      },

      async setPlaylist() {
        await this.getPlaylistData();

        this.filterPlaylistsBySchedulerAndPriority();
        this.currentSlideIndex = 0;

        if (this.playSlider && this.playlistItems.length > 0) {
          await this.initPlaylist();
        }
      },

      filterPlaylistsBySchedulerAndPriority() {
        const tempFilteredSlides = this.slides.filter((slide) => {
          if (!slide.scheduler || slide.scheduler.available === 'always') {
            return true;
          }

          const { type, timeToTime, daysTime, daysTimes, daysHourType, days } = slide.scheduler;

          const fromDate = timeToTime.from ? moment(timeToTime.from).toDate() : null;
          const toDate = timeToTime.to ? moment(timeToTime.to).toDate() : null;

          const isFromDateValid = fromDate ? moment(fromDate).isBefore() : true;
          const isToDateValid = toDate ? moment(toDate).isAfter() : true;

          const isInRangeOfDays = isFromDateValid && isToDateValid;

          if (!isInRangeOfDays || type === 'time-to-time') {
            return isInRangeOfDays;
          }

          const now = moment();
          const currentDay = DAYS_OF_THE_WEEK[now.isoWeekday() - 1];
          const midnight = now.clone().startOf('day');
          const currentMinute = now.diff(midnight, 'minutes');

          const isValidDay =
            (daysHourType === 'same-hours' && days.length === 0) || days.includes(currentDay);

          if (!isValidDay) {
            return false;
          }

          if (daysHourType === 'same-hours') {
            return currentMinute >= daysTime[0] && currentMinute <= daysTime[1];
          }

          const [startMinute, endMinute] = daysTimes[currentDay];

          return currentMinute >= startMinute && currentMinute <= endMinute;
        });
        const hasPriorityItems =
          tempFilteredSlides.filter((slide) => slide.display_priority === DISPLAY_PRIORITY.PRIORITY)
            .length > 0;
        this.filteredSlides = tempFilteredSlides.filter((slide) =>
          hasPriorityItems ? slide.display_priority === DISPLAY_PRIORITY.PRIORITY : true,
        );
      },

      async getVideos() {
        const videos = {};
        const videoSlides = this.slides.filter(
          (item) => item.item_type && item.item_type.includes('video'),
        );

        for (let i = 0; videoSlides.length > i; i++) {
          const video = videoSlides[i];

          const videoUrl = this.getEncodedURL(this.baseUrl, video.item_url);
          const localVideo = await this.fetchVideo(videoUrl);

          if (video) {
            videos[video.item_id] = localVideo;
          }
        }

        this.videos = videos;
      },

      async fetchVideo(url) {
        try {
          const response = await fetch(url);
          const video = await response.blob();
          const videoUrl = URL.createObjectURL(video);

          return { url: videoUrl, file: video };
        } catch (error) {
          console.log(`Error fetching Video (${url}):`, error);
          return null;
        }
      },

      async initPlaylist() {
        if (this.filteredSlides.length >= 2) {
          this.currentSlideIndex = 0;
          this.nextSlideIndex = 1;
        }

        await this.startSlider();
      },

      async startSlider() {
        if (this.filteredSlides.length === 0) {
          return await this.initTimer(1000);
        }

        const firstSlide = this.filteredSlides[0];

        await this.initTimer(firstSlide.display_timer * 1000);
      },

      async initTimer(after) {
        this.destroyTimer();
        const slideDuration = after > 1000 ? after : 1000;

        await new Promise((resolve, reject) => {
          this.time = setTimeout(async () => {
            try {
              await this.nextSlide();
              resolve();
            } catch (error) {
              console.error(error);
              reject();
            } finally {
              this.destroyTimer();
            }
          }, slideDuration);
        });
      },

      destroyTimer() {
        if (this.time) {
          clearTimeout(this.time);
        }
      },

      async nextSlide() {
        const newCurrentSlideIndex = (this.currentSlideIndex + 1) % this.filteredSlides.length;
        const newNextSlideIndex = (this.currentSlideIndex + 2) % this.filteredSlides.length;

        // Minimizing reactivity
        if (newCurrentSlideIndex !== this.currentSlideIndex) {
          this.currentSlideIndex = newCurrentSlideIndex;
        }
        if (newNextSlideIndex !== this.nextSlideIndex) {
          this.nextSlideIndex = newNextSlideIndex;
        }

        this.restartSignal = new Date().getTime().toString();
        await this.initTimer((this.currentSlide ? this.currentSlide.display_timer : 1) * 1000);
      },

      displaySlide(slide) {
        return this.currentSlide?.item_id === slide.item_id;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/animations.scss';

  .playlist-player {
    height: 100%;
    overflow: hidden;
  }

  .slideIn {
    transform: scaleX(1);
    transform-origin: right;
    transition: 0.5s transform ease-in, 0.5s visibility ease-in;
  }

  .slideOut {
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s transform ease-in, 0.5s visibility ease-in;
  }

  .fadeIn,
  .fadeOut {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
</style>

<style lang="scss">
  .playlist-slide {
    position: absolute;
    overflow: hidden;
  }

  .playlist-slide-video .playlist-slide-audio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .playlist-slide {
    width: 100%;
    height: 100%;

    .playlist-slide-image,
    .playlist-slide-video,
    .playlist-slide-audio,
    .playlist-slide-app-rss,
    .playlist-slide-app-table,
    .playlist-slide-trafiklab-table,
    .playlist-slide-weather-table,
    .playlist-slide-pdf,
    .player-slide-youtube {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .playlist-slide-video .playlist-slide-audio {
      &.stretch {
        object-fit: fill;
      }

      &.origin {
        object-fit: none;
      }
    }

    .player-slide-youtube {
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .playlist-background {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0) url('../../assets/img/layoutDesigner/playlist.png') no-repeat
      center;
    background-size: 70px;
  }
</style>
