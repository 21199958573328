import config from '../config';
import http from '@/utils/http';

export function apiGetScreenLayout(screenId) {
  const endpoint = `${config.apiUrl}userapi/screens/${screenId}/layout`;
  return http.get(endpoint);
}

export const apiGetScreenLayoutChildren = async (screenId) => {
  return await http.get(`${config.apiUrl}userapi/screens/${screenId}/layout/children`);
};

export const apiGetScreenLayoutMediaAsset = async (screenId) => {
  const response = await http.get(`${config.apiUrl}userapi/screens/${screenId}/layout/media-asset`);

  return response.data;
};
