import Vue, { getCurrentInstance } from 'vue';
import VueRouter from 'vue-router';
import Player from '@/views/Player.vue';
import RegisterCode from '@/views/RegisterCode.vue';
import NoLayout from '@/views/NoLayout.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import { isLoggedIn } from '@/services/auth';
import { SCREEN_LAYOUT_ENUM, SCREEN_ID_ENUM } from '@/constants';
import localforage from 'localforage';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!isLoggedIn()) {
    next('/');
    return;
  }
  if (!localStorage.getItem(SCREEN_ID_ENUM)) {
    localStorage.clear();
    localforage.clear();
    next('/');
    return;
  }
  next();
};

const ifAuthenticated = (to, from, next) => {
  if (isLoggedIn() && localStorage.getItem(SCREEN_LAYOUT_ENUM)) {
    next(`/player/${localStorage.getItem(SCREEN_LAYOUT_ENUM)}`);
    return;
  } else if (isLoggedIn()) {
    next(`/no-layout`);
    return;
  }
  next();
};

var app_title = 'PicFlow';

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: RegisterCode,
      beforeEnter: ifAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
    {
      path: '/no-layout',
      component: NoLayout,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
    {
      path: '/player/:layout_id',
      component: Player,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
    {
      path: '*',
      component: PageNotFound,
      meta: {
        title: () => {
          return `${app_title}`;
        },
        layout: 'empty',
      },
    },
  ],
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to);
  });
});

export default router;

export function useRouter() {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$root.$router;
}
