<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'AudioSlide',
  });
</script>

<script setup lang="ts">
  import { computed, onMounted, ref, toRefs, watch } from 'vue';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';

  const props = withDefaults(
    defineProps<{
      show?: boolean;
      restartSignal?: string | null;
      itemUrl: string;
      itemId: string;
      itemType: string;
      volume: number;
      imageMode?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
      mute?: boolean;
    }>(),
    {
      show: false,
      restartSignal: null,
      imageMode: 'cover',
    },
  );
  const { show, restartSignal, imageMode, itemUrl, itemId, itemType, volume, mute } = toRefs(props);

  const audioRef = ref<HTMLAudioElement | null>(null);

  const audioUrl = computed(() => getItemLocalUrl(itemUrl.value, itemId.value));
  const defaultAudioUrl = computed(() => getItemUrl(itemUrl.value));

  const isWeb = computed(() => {
    return window.isWeb as boolean;
  });

  watch([show, restartSignal], ([newShow, newRestartSignal], [_oldShow, oldRestartSignal]) => {
    if (audioRef.value === null) return;

    if (newShow && newRestartSignal !== oldRestartSignal && newRestartSignal !== null) {
      resetAudio(false);
    }
  });

  watch(show, (newShow, oldShow) => {
    if (audioRef.value === null) return;

    // handle visible to hidden
    if (oldShow === true && newShow === false) {
      resetAudio();
    }
  });

  onMounted(() => {
    if (show.value) {
      playAudio();
    }
  });

  function resetAudio(pause = true) {
    if (audioRef.value === null) return;

    audioRef.value.pause();
    audioRef.value.currentTime = 0;

    if (!pause) {
      playAudio();
    }
  }

  function playAudio() {
    if (audioRef.value === null) return;

    audioRef.value.volume = volume.value;
    audioRef.value.muted = mute.value;
    audioRef.value.currentTime = 0;

    const playPromise = audioRef.value.play();

    // browsers released before 2019 may not return a value
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((_error) => {});
    }
  }

  function handleAudioError() {
    console.error(`Failed to load: ${audioUrl.value}`);
  }

  function handleDefaultAudioError() {
    console.error(`Failed to load default: ${defaultAudioUrl.value}`);
  }
</script>

<template>
  <audio
    ref="audioRef"
    controls
    loop
    texture
    class="playlist-slide-audio"
    :style="{
      objectFit: imageMode,
      visibility: show ? 'visible' : 'hidden',
    }"
  >
    <source :src="audioUrl" :type="itemType" @error="handleAudioError" />
    <source
      v-if="!isWeb"
      :src="defaultAudioUrl"
      :type="itemType"
      @error="handleDefaultAudioError"
    />
  </audio>
</template>

<style scoped lang="scss">
  .playlist-slide-audio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.stretch {
      object-fit: fill;
    }

    &.origin {
      object-fit: none;
    }
  }
</style>
